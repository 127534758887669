<template>
  <a-modal
    :visible="visible"
    title="批量添加资源数量"
    width="1600px"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div>
      <div>1、选择目标用户</div>
      <!-- 穿梭框 -->
      <!-- @search="handleSearch" -->
      <a-transfer
        :titles="['选择租户', '已选择租户']"
        :data-source="allocationData"
        :target-keys="targetKeys"
        show-search
        :filter-option="filterOption"
        @change="handleChange"
        :rowKey="(record) => record.id"
        :list-style="{
          width: '350px',
          height: '950px',
        }"
      >
        <!-- :render="(item) => `${item.rentname}(${item.id})`"
        :rowKey="(record) => record.id" -->
        <template
          slot="children"
          slot-scope="{
            props: {
              direction,
              filteredItems,
              selectedKeys,
              disabled: listDisabled,
            },
            on: { itemSelectAll, itemSelect },
          }"
        >
          <a-table
            @change="tenantTableChange"
            :pagination="pagination"
            :row-selection="
              getRowSelection({
                disabled: listDisabled,
                selectedKeys,
                itemSelectAll,
                itemSelect,
              })
            "
            :columns="nameColumns"
            :data-source="filteredItems"
            :custom-row="
              ({ key, disabled: itemDisabled }) => ({
                on: {
                  click: () => {
                    if (itemDisabled || listDisabled) return
                    itemSelect(key, !selectedKeys.includes(key))
                  },
                },
              })
            "
          >
            <template slot="rentname" slot-scope="text, record">
              {{ text || record.name }} ({{ record.id }})
            </template>
          </a-table>
        </template>
      </a-transfer>
    </div>
    <div>
      <div>2、增加资源数量</div>
      <a-table
        style="margin-top: 15px; width: 800px"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :rowKey="(record) => record.type"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <span slot="customTitle" class="title_box">
          <span>有效时长</span>
          <span
            ><a-checkbox @change="onChange"> 统一设为365天 </a-checkbox></span
          >
        </span>
        <div slot="type" slot-scope="text, record">
          <!-- {{ typeName[record.type] }} -->
          {{ resourceTypeData[record.type].name }}
        </div>
        <!-- 资源数量可填 -->
        <div slot="quantity" slot-scope="text, record" class="input_box">
          <a-input
            placeholder="请填写数量"
            v-model="record.quantity"
            :disabled="
              !selectedRowKeys.includes(record.type) || record.disabled
            "
          ></a-input>
          <span style="width: 40px; text-align: right">{{
            record.quantityUnit
          }}</span>
        </div>
        <!-- 有效时长可填 -->
        <div slot="duration" slot-scope="text, record" class="input_box">
          <a-input
            placeholder="请填写"
            v-model="record.duration"
            :disabled="!selectedRowKeys.includes(record.type)"
          ></a-input
          ><span style="width: 40px; text-align: right">天</span>
        </div>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import _ from 'lodash'
const difference = _.difference

const columns = [
  {
    title: '资源类型',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '资源数量',
    dataIndex: 'quantity',
    key: 'quantity',
    scopedSlots: { customRender: 'quantity' },
    width: 230,
  },

  {
    dataIndex: '有效时长',
    key: 'duration',
    slots: { title: 'customTitle' },
    scopedSlots: { customRender: 'duration' },
  },
]

const nameColumns = [
  {
    title: 'name',
    dataIndex: 'rentname',
    scopedSlots: { customRender: 'rentname' },
  },
]

export default {
  name: 'BatchAddSource',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns,
      data: [],
      selectedRowKeys: [],
      /* ---- */
      allocationData: [],
      targetKeys: [],
      alreadyTenantIdArr: [],
      alreadyTenantArr: [],
      /* ------ */
      nameColumns,
      pagination: {
        current: 1, // 当前页数
        pageSize: 10, // 当前页显示数据的大小
        total: 0,
        size: 'small',
      },
    }
  },
  async created() {
    // console.log(this.isEdit, this.record)
    await this.init()
    // this.$nextTick(() => {
    //   this.$forceUpdate()
    // })
  },
  methods: {
    tenantTableChange({ pageSize, current }) {
      // console.log(pagination)
      this.getTenantInfoList({ page: current, size: pageSize })
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: (item) => ({
          props: { disabled: item.disabled },
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key)
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys)
          itemSelectAll(diffKeys, selected)
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected)
        },
        selectedRowKeys: selectedKeys,
      }
    },
    /* 获取租户列表列表 */
    getTenantInfoList(params) {
      // let params = {
      //   page: this.pagination.current,
      //   size: this.pagination.pageSize,
      // }

      return new Promise((resolve, reject) => {
        /* 所有租户 */

        this.$api
          ._getTenantInfoList(params)
          .then((res) => {
            if (res.code == 0) {
              this.allocationData = res.data.content
              this.pagination.total = res.data.totalElements
              // this.count.totalCount = res.data.totalElements
              this.pagination.current = res.data.number + 1
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    /* ----------------------------------------------------------------- */

    /* 获取租户列表 */
    getTenantList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getAllTenantList()
          .then((res) => {
            if (res.code === 0) {
              this.allocationData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /* 穿梭时触发 */
    handleChange(targetKeys, direction, moveKeys) {
      console.log(targetKeys, 'mmm')
      this.targetKeys = targetKeys
    },
    /* 穿梭框搜索是触发 */
    handleSearch(direction, value) {},
    /* 搜索筛选方法 */
    filterOption(inputValue, option) {
      if (
        option.name.indexOf(inputValue) > -1 ||
        option.id.indexOf(inputValue) > -1
      ) {
        return true
      } else {
        return false
      }
    },
    /* ------------------------ */
    /* 获取资源配置项信息 */
    getResourceTypeInfo() {
      return new Promise((resolve, reject) => {
        this.$api.getResourceTypes().then((res) => {
          if (res.code === 0) {
            this.resourceTypeData = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async init() {
      /* 资源类型获取 */
      await this.getResourceTypeInfo().then(() => {
        let resourceTypeKey = Object.keys(this.resourceTypeData)
        this.data = resourceTypeKey.map((item, index) => {
          return {
            // id: index + 1,
            type: item,
            quantity: '1',
            quantityUnit: this.resourceTypeData[item].unit,
            duration: '0',
            durationUnit: 'DAYS',
          }
        })
      })
      // this.getTenantList()
      this.getTenantInfoList({
        page: this.pagination.current,
        size: this.pagination.pageSize,
      })

      /* 只能设置时间的资源配置项 */
      let disabledArr = Object.keys(this.resourceTypeData).filter((item) => {
        return item.split('_')[0] === 'DURATION'
      })
      console.log(disabledArr)

      this.data.forEach((item, index) => {
        // this.$set(item, 'id', index + 1)
        if (disabledArr.includes(item.type)) {
          this.$set(item, 'disabled', true)
        }
      })
    },
    handleCancel() {
      // this.$emit('refresh')
      this.$emit('update:visible', false)
    },
    handleOk() {
      let _data = this.data.filter((item) =>
        this.selectedRowKeys.includes(item.type)
      )
      // console.log(_data)
      // console.log(this.targetKeys)
      let data = {
        orderSource: 'CALLZONE_OPN',
        resources: _data,
        tenantIds: this.targetKeys,
      }

      let isError = _data.every((item) => {
        return item.quantity > 0 && item.duration > 0
      })
      // console.log(isError)
      if (!isError) {
        this.$message.error('请检查资源设置输入值是否>1')
        return
      }
      console.log(data)
      this.addSource(data).then(() => {
        this.$message.success('增加成功')
        this.$emit('refresh')
      })
    },
    /* 统一设置checkbox */
    onChange({ target }) {
      // console.log(target)
      if (target.checked) {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = 365
          }
        })
      } else {
        this.data.forEach((item) => {
          if (this.selectedRowKeys.includes(item.type)) {
            item.duration = ''
          }
        })
      }
    },
    onSelectChange(selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    /* 添加资源数量 */
    addSource(data) {
      return new Promise((resolve, reject) => {
        this.$api.batchAddSourceOfTenant(data).then((res) => {
          if (res.code == 0) {
            resolve()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  display: flex;
  justify-content: space-between;
}

/deep/ .ant-table-header-column {
  width: 100%;
}

/deep/ .input_double {
  display: flex;
  align-items: center;
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
