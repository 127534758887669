<!--
 * @Author: XSJ 13592841305@163.com
 * @Date: 2023-08-04 10:31:47
 * @LastEditors: XSJ 13592841305@163.com
 * @LastEditTime: 2023-11-09 10:01:14
 * @FilePath: \cz-operation\src\views\tenantManage\tenantList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tenantList">
    <div class="top">
      <div class="title">
        <div class="title-text">租户列表</div>
        <!-- <div class="title-description">
          <div class="count-box">
            <span>租户总数：{{ count.totalCount }}</span>
          </div>
          <div class="count-box">
            <span>跟进中：{{ count.followUpCount }}</span>
          </div>
          <div class="count-box">
            <span>未跟进：{{ count.unFollowUpCount }}</span>
          </div>
          <div class="count-box">
            <span>待认证审核：{{ count.unAuditCount }}</span>
          </div>
        </div> -->
        <div class="title_action">
          <a-button type="primary" @click="batchAddSource"
            >批量增加资源数量</a-button
          >
        </div>
      </div>

      <div class="search">
        <a-tabs v-model="activeKey" @change="handleFilter">
          <a-tab-pane key="1" tab="全部"></a-tab-pane>
          <a-tab-pane key="2" tab="跟进中"></a-tab-pane>
          <a-tab-pane key="3" tab="未跟进"></a-tab-pane>
          <a-tab-pane key="4" tab="待认证审核"></a-tab-pane>
        </a-tabs>
        <a-form :form="form" layout="inline">
          <a-form-item>
            <a-input
              style="width: 300px"
              placeholder="请输入租户名称"
              v-decorator="['key', { initialValue: '' }]"
              allow-clear
              @pressEnter="search"
              @change="handleClean"
            ></a-input>
          </a-form-item>
          <a-form-item>
            <a-select
              placeholder="请选择状态"
              v-decorator="['isActive']"
              style="width: 150px"
              @change="handleFilter"
              allow-clear
            >
              <!-- <a-select-option value="">全部</a-select-option> -->
              <a-select-option value="1">正常</a-select-option>
              <a-select-option value="0">停用</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <a-table
      :columns="columns"
      :data-source="tenantInfoList"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="tablePageChange"
      :scroll="{ x: 1400 }"
    >
      <template slot="adminId" slot-scope="text, record">
        <!-- {{ adminInfo(text).name }} -->
        {{ record.admin.name || '--' }}
      </template>
      <template slot="phoneNumber" slot-scope="text">{{
        text || '--'
      }}</template>
      <!-- 状态 -->
      <template slot="status" slot-scope="text, record">
        <!-- {{ record.active ? '正常' : '不正常' }} -->
        <span v-if="record.active" style="color: #28c28e">●正常</span>
        <span v-else style="color: #ff4453">●停用</span>
      </template>

      <template slot="createdAt" slot-scope="text">{{ text || '--' }}</template>
      <!-- 操作列 -->
      <div slot="operation" slot-scope="text, record">
        <a v-if="record.active" @click="stop(record)">禁用</a>
        <!-- <a-divider type="vertical" /> -->
        <a v-else @click="open(record)">启用</a>
        <a-divider type="vertical" />

        <a @click="toTenantDetail(record)">详情</a>
        <a-divider type="vertical" />
        <a v-if="!record.tracking" @click="sign(record, 1)">标记跟进</a>
        <!-- <a-divider type="vertical" /> -->
        <a v-else @click="sign(record, 0)">取消标记跟进</a>
      </div>
    </a-table>
    <!-- 批量添加资源 -->
    <batch-add-source
      :visible.sync="addSourceVisible"
      v-if="addSourceVisible"
      @refresh="refresh"
    ></batch-add-source>
  </div>
</template>

<script>
import batchAddSource from './modals/batchAddSource.vue'

const columns = [
  {
    title: '租户id',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'left',
    fixed: 'left',
  },
  {
    title: '租户名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'left',
    fixed: 'left',
    width: 200,
  },
  {
    title: '租户管理员',
    dataIndex: 'adminId',
    key: 'adminId',
    scopedSlots: { customRender: 'adminId' },
    align: 'left',
  },
  {
    title: '手机号',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    scopedSlots: { customRender: 'phoneNumber' },
    align: 'left',
    width: 120,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
    width: 80,
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: { customRender: 'createdAt' },
    align: 'left',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'left',
    fixed: 'right',
    width: 200,
  },
]

export default {
  name: 'TenantList',
  components: { batchAddSource },
  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      tenantInfoList: [],
      pagination: {
        current: 1, // 当前页数
        pageSize: 10, // 当前页显示数据的大小
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `总共${total}`,
        pageSizeOptions: ['10', '20', '30'],
      },
      activeKey: '1',
      addSourceVisible: false /* 资源窗口开关 */,
      count: {
        totalCount: '-',
        followUpCount: '-',
        unFollowUpCount: '-',
        unAuditCount: '-',
      },
    }
  },
  created() {
    this.init()
  },

  // computed: {
  //   adminInfo() {
  //     return async function (value) {
  //       let info = await this.$api.getAdminInfo(value)
  //     }
  //   },
  // },

  methods: {
    async init() {
      /* 获取筛选条件 */
      this.getTenantInfoList()
    },
    /* 处理sninput框清空事件 */
    handleClean(e) {
      console.log(e)
      if (e.target.value == '') {
        this.$nextTick(() => {
          this.getTenantInfoList()
        })
      }
    },
    /* 刷新 */
    refresh() {
      this.addSourceVisible = false
      this.getTenantInfoList()
    },

    /* 跳转租户详情页 */
    toTenantDetail(record) {
      this.$router.push({
        name: 'TenantDetail',
        query: {
          id: record.id,
        },
      })
    },

    /* 获取租户分类统计数 */
    async getTenantTypeCount() {
      //  this.
    },

    /* 获取租户列表列表 */
    getTenantInfoList() {
      let params = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
      }
      let searchValue = this.form.getFieldsValue()

      params = {
        ...params,
        ...searchValue,
        // id: searchValue.nameLike,
      }

      if (this.activeKey == '2') {
        this.$set(params, 'isTracking', 1)
      } else if (this.activeKey == '3') {
        this.$set(params, 'isTracking', 0)
      } else if (this.activeKey == '4') {
        this.$set(params, 'authenticationStatus', '2')
        // this.$set(params, 'authenticationStatus','')
      }

      return new Promise((resolve, reject) => {
        /* 所有租户 */
        console.log(this.activeKey)
        this.$api
          ._getTenantInfoList(params)
          .then((res) => {
            if (res.code == 0) {
              console.log(res, '租户列表')
              this.tenantInfoList = res.data.content
              this.pagination.total = Number(res.data.totalElements)
              this.count.totalCount = res.data.totalElements
              this.pagination.current = res.data.number + 1
              resolve()
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    /* 分页设置改变 */
    tablePageChange({ pageSize, current }) {
      this.pagination.pageSize = pageSize
      this.pagination.current = current
      this.getTenantInfoList()
    },
    /* 查找 */
    search() {
      this.$nextTick(() => {
        this.pagination.current = 1
        this.getTenantInfoList()
      })
    },

    /* 筛选条件改变 */
    handleFilter() {
      this.$nextTick(() => {
        this.pagination.current = 1
        this.getTenantInfoList()
      })
    },
    /* 打开批量增加资源窗口 */
    batchAddSource() {
      this.addSourceVisible = true
    },

    /* 标记跟进、取消标记跟进 */
    sign(record, value) {
      let data = {
        renterid: record.id,
        status: value,
      }

      if (value) {
        this.$api.trackTenant(record.id).then((res) => {
          if (res.code == 0) {
            this.$message.success('标记成功')
            this.refresh()
          } else {
          }
        })
      } else {
        this.$api.unTrackTenant(record.id).then((res) => {
          if (res.code == 0) {
            this.$message.success('取消标记')
            this.refresh()
          } else {
          }
        })
      }
    },

    /* 禁用 */
    stop(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: '',
              theme: 'filled',
            },
          }),
        title: (h) => (
          <div style="display:flex;align-items: center">
            <a-icon
              type="exclamation-circle"
              theme="filled"
              style="color: #FF4453;font-size: 24px;margin-right: 15px"
            ></a-icon>
            <span>禁用</span>
          </div>
        ),

        content: (h) => (
          <div>
            <span style="color: #FF4453">
              禁用后该租户所有操作将被影响使用，
            </span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.disableTenant(value.id).then((res) => {
            console.log(res)
            _this.$message.success('已禁用！')
            // _this.reload()
            // _this.refresh()
            _this.refresh()
          })
        },
        onCancel() {},
      })
    },

    /* 启用 */
    open(value) {
      let _this = this
      this.$confirm({
        icon: () =>
          this.$createElement('a-icon', {
            props: {
              type: 'exclamation-circle',
              theme: 'filled',
            },
          }),
        title: '启用',
        content: (h) => (
          <div>
            <span style="color: #FF4453">恢复后该租户可以继续操作，</span>
            <span>你还要继续操作吗</span>
          </div>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk() {
          _this.$api.enableTenant(value.id).then((res) => {
            console.log(res)
            _this.$message.success('已启用！')
            _this.refresh()
          })
        },
        onCancel() {},
      })
    },

    /* 去除对象中的空值、undefined、‘’ */
    cleanEmpty(value) {
      return Object.keys(value)
        .filter(
          (item) =>
            value[item] !== undefined &&
            value[item] !== '' &&
            value[item] !== null
        )
        .reduce((prev, curr) => ({ ...prev, [curr]: value[curr] }), {})
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/CSS/global.less';

.tenantList {
  background-color: #fff;
  height: calc(100vh - 120px);
  // margin-top: 5px;
  padding: 15px;
  border-radius: 3px;
  // overflow: auto;
}

.top {
  .title {
    // height: 100px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    position: relative;
    .title-text {
      margin-left: 10px;
      margin-right: 25px;
      position: relative;
      font-family: '.PingFang SC S0pxibold';
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      color: #151515;
      /* 伪类 */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        transform: translateY(-50%);
        width: 4px;
        height: 20px;
        border-radius: 3px;
        background: #1890ff;
      }
    }
    .title-description {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      color: #999;
      div {
        width: 150px;
        padding: 0 20px;
      }
      .count-box:not(:last-child) {
        border-right: 1px solid #999;
      }
    }
    .title_action {
      position: absolute;
      right: 10px;
    }
  }

  /deep/ .search {
    // width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    // align-items: center;
    .search-button {
      position: absolute;
      right: 0;
      button {
        width: 100px;
      }
    }
    // .ant-form {
    //   display: flex;
    //   align-items: center;
    // }
    // .ant-form-item {
    //   // width: 280px;
    //   margin-bottom: 0;
    //   margin-right: 16px;
    // }
  }
}

/deep/ .search-result {
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #4e4e4e;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  div {
    margin-right: 30px;
    .ant-select-selection {
      border: none;
      width: 130px;
    }
  }
}

/deep/ .ant-table-body {
  overflow-y: hidden;
}
</style>
