var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"批量添加资源数量","width":"1600px"},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',[_c('div',[_vm._v("1、选择目标用户")]),_c('a-transfer',{attrs:{"titles":['选择租户', '已选择租户'],"data-source":_vm.allocationData,"target-keys":_vm.targetKeys,"show-search":"","filter-option":_vm.filterOption,"rowKey":function (record) { return record.id; },"list-style":{
        width: '350px',
        height: '950px',
      }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"children",fn:function(ref){
        var ref_props = ref.props;
        var direction = ref_props.direction;
        var filteredItems = ref_props.filteredItems;
        var selectedKeys = ref_props.selectedKeys;
        var listDisabled = ref_props.disabled;
        var ref_on = ref.on;
        var itemSelectAll = ref_on.itemSelectAll;
        var itemSelect = ref_on.itemSelect;
return [_c('a-table',{attrs:{"pagination":_vm.pagination,"row-selection":_vm.getRowSelection({
              disabled: listDisabled,
              selectedKeys: selectedKeys,
              itemSelectAll: itemSelectAll,
              itemSelect: itemSelect,
            }),"columns":_vm.nameColumns,"data-source":filteredItems,"custom-row":function (ref) {
                  var key = ref.key;
                  var itemDisabled = ref.disabled;

                  return ({
              on: {
                click: function () {
                  if (itemDisabled || listDisabled) { return }
                  itemSelect(key, !selectedKeys.includes(key))
                },
              },
            });
        }},on:{"change":_vm.tenantTableChange},scopedSlots:_vm._u([{key:"rentname",fn:function(text, record){return [_vm._v(" "+_vm._s(text || record.name)+" ("+_vm._s(record.id)+") ")]}}],null,true)})]}}])})],1),_c('div',[_c('div',[_vm._v("2、增加资源数量")]),_c('a-table',{staticStyle:{"margin-top":"15px","width":"800px"},attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":false,"rowKey":function (record) { return record.type; },"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      }},scopedSlots:_vm._u([{key:"type",fn:function(text, record){return _c('div',{},[_vm._v(" "+_vm._s(_vm.resourceTypeData[record.type].name)+" ")])}},{key:"quantity",fn:function(text, record){return _c('div',{staticClass:"input_box"},[_c('a-input',{attrs:{"placeholder":"请填写数量","disabled":!_vm.selectedRowKeys.includes(record.type) || record.disabled},model:{value:(record.quantity),callback:function ($$v) {_vm.$set(record, "quantity", $$v)},expression:"record.quantity"}}),_c('span',{staticStyle:{"width":"40px","text-align":"right"}},[_vm._v(_vm._s(record.quantityUnit))])],1)}},{key:"duration",fn:function(text, record){return _c('div',{staticClass:"input_box"},[_c('a-input',{attrs:{"placeholder":"请填写","disabled":!_vm.selectedRowKeys.includes(record.type)},model:{value:(record.duration),callback:function ($$v) {_vm.$set(record, "duration", $$v)},expression:"record.duration"}}),_c('span',{staticStyle:{"width":"40px","text-align":"right"}},[_vm._v("天")])],1)}}])},[_c('span',{staticClass:"title_box",attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('span',[_vm._v("有效时长")]),_c('span',[_c('a-checkbox',{on:{"change":_vm.onChange}},[_vm._v(" 统一设为365天 ")])],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }